.dashboard__centered-text {
    text-align: center;
    font-size: 2rem;
    color: black;
    font-family: 'Signika Negative';
    min-height: 60%;
    display: flex;
    line-height: initial;
    justify-content: center;
    align-items: center;
}

.dashboard__centered-element {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
}

.dashboard__project-setting__logo {
    width: 100px;
    max-width: 70vw;
    border-radius: 100px;
    margin: 1rem;
}

.dashboard-project-info__info-title {
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 0.2rem 0.5rem;
}

.dashboard-project-info__info-text {
    display: flex;
    align-items: center;
}

.dashboard-project-info__raffles-area {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.dashboard-project-info__raffle {
    display: flex;
    cursor: default;
    margin: 0.5rem;
    flex-flow: column;
    width: 135px;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background: linear-gradient(to bottom right, #9d97f3, #48529d);
}

.dashboard-project-info__raffle-img-container {
    width: 120px;
    overflow: hidden;
    aspect-ratio: 1 / 1;
}

.dashboard-project-info__raffle-img {
    width: 100%
}

.dashboard-project-info__raffle-name {
    font-size: 1rem;
    color: white;
    width: 100%;
    text-align: center;
    padding-top: 0.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dashboard-project-info__raffle-btn {
    align-items: center;
    background: #5c64ff;
    border: 1px solid #9187ff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    font-family: Signika Negative;
    font-size: 0.8rem;
    justify-content: center;
    /* margin: 0.5rem; */
    padding: 0.2rem 2rem;
}

.dashboard-project-info__raffle-btn:hover {
    background: #9187ff;
    transition: all .2s ease-in;
}

.dashboard-project-info__raffle-btn:disabled {
    background: #62648e;
}


.dashboard-project-info__raffle-big-btn {
    align-items: center;
    background: #5c64ff;
    border: 1px solid #9187ff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-flow: row wrap;
    font-family: Signika Negative;
    font-size: 1.3rem;
    justify-content: center;
    /* margin: 0.5rem; */
    padding: 0.2rem 2rem;
}

.dashboard-project-info__raffle-big-btn:hover {
    background: #9187ff;
    transition: all .2s ease-in;
}

.dashboard-project-info__raffle-big-btn:disabled {
    background: #62648e;
}

/* Form */

.dashboard-raffles__create-raffle-form .button,
.dashboard-raffles__create-raffle-form .message,
.customSelect,
.dashboard-raffles__create-raffle-form .select,
.dashboard-raffles__create-raffle-form .textarea,
.dashboard-raffles__create-raffle-form .text-input,
.dashboard-raffles__create-raffle-form .option-input+label,
.dashboard-raffles__create-raffle-form .checkbox-input+label,
.dashboard-raffles__create-raffle-form .label {
    padding: 0.75em 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    line-height: normal;
    border-radius: 0;
    border: none;
    background: none;
    display: block;
}

.dashboard-raffles__create-raffle-form .label {
    font-weight: bold;
    color: white;
    padding-top: 0;
    padding-left: 0;
    letter-spacing: 0.025em;
    font-size: 1.125em;
    line-height: 1.25;
    position: relative;
    z-index: 100;
}

.required .dashboard-raffles__create-raffle-form .label:after,
.dashboard-raffles__create-raffle-form .required .label:after {
    content: " *";
    color: #E8474C;
    font-weight: normal;
    font-size: 0.75em;
    vertical-align: top;
}

.customSelect,
.dashboard-raffles__create-raffle-form .select,
.dashboard-raffles__create-raffle-form .textarea,
.dashboard-raffles__create-raffle-form .text-input,
.dashboard-raffles__create-raffle-form .option-input+label,
.dashboard-raffles__create-raffle-form .checkbox-input+label {
    font: inherit;
    line-height: normal;
    width: 100%;
    box-sizing: border-box;
    background: #222222;
    color: white;
    position: relative;
}

.customSelect:placeholder,
.dashboard-raffles__create-raffle-form .select:placeholder,
.dashboard-raffles__create-raffle-form .textarea:placeholder,
.dashboard-raffles__create-raffle-form .text-input:placeholder,
.dashboard-raffles__create-raffle-form .option-input+label:placeholder,
.dashboard-raffles__create-raffle-form .checkbox-input+label:placeholder {
    color: white;
}

.customSelect:-webkit-autofill,
.dashboard-raffles__create-raffle-form .select:-webkit-autofill,
.dashboard-raffles__create-raffle-form .textarea:-webkit-autofill,
.dashboard-raffles__create-raffle-form .text-input:-webkit-autofill,
.dashboard-raffles__create-raffle-form .option-input+label:-webkit-autofill,
.dashboard-raffles__create-raffle-form .checkbox-input+label:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #111111 inset;
    -webkit-text-fill-color: white;
    border-top-color: #111111;
    border-left-color: #111111;
    border-right-color: #111111;
}

.customSelect:not(:focus):not(:active).error,
.dashboard-raffles__create-raffle-form .select:not(:focus):not(:active).error,
.dashboard-raffles__create-raffle-form .textarea:not(:focus):not(:active).error,
.dashboard-raffles__create-raffle-form .text-input:not(:focus):not(:active).error,
.dashboard-raffles__create-raffle-form .option-input+label:not(:focus):not(:active).error,
.dashboard-raffles__create-raffle-form .checkbox-input+label:not(:focus):not(:active).error,
.error .customSelect:not(:focus):not(:active),
.error .dashboard-raffles__create-raffle-form .select:not(:focus):not(:active),
.dashboard-raffles__create-raffle-form .error .select:not(:focus):not(:active),
.error .dashboard-raffles__create-raffle-form .textarea:not(:focus):not(:active),
.dashboard-raffles__create-raffle-form .error .textarea:not(:focus):not(:active),
.error .dashboard-raffles__create-raffle-form .text-input:not(:focus):not(:active),
.dashboard-raffles__create-raffle-form .error .text-input:not(:focus):not(:active),
.error .dashboard-raffles__create-raffle-form .option-input+label:not(:focus):not(:active),
.dashboard-raffles__create-raffle-form .error .option-input+label:not(:focus):not(:active),
.error .dashboard-raffles__create-raffle-form .checkbox-input+label:not(:focus):not(:active),
.dashboard-raffles__create-raffle-form .error .checkbox-input+label:not(:focus):not(:active) {
    background-size: 8px 8px;
    background-image: linear-gradient(135deg, rgba(232, 71, 76, 0.5), rgba(232, 71, 76, 0.5) 25%, transparent 25%, transparent 50%, rgba(232, 71, 76, 0.5) 50%, rgba(232, 71, 76, 0.5) 75%, transparent 75%, transparent);
    background-repeat: repeat;
}

.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .customSelect.customSelectFocus,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .customSelect:active,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .select:active,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .textarea:active,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .text-input:active,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .option-input+label:active,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .checkbox-input+label:active,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .customSelect:focus,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .select:focus,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .textarea:focus,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .text-input:focus,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .option-input+label:focus,
.dashboard-raffles__create-raffle-form:not(.has-magic-focus) .checkbox-input+label:focus {
    background: #4E4E4E;
}

.dashboard-raffles__create-raffle-form .message {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    font-size: 0.625em;
    color: white;
}

.dashboard-raffles__create-raffle-form .option-input,
.dashboard-raffles__create-raffle-form .checkbox-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.dashboard-raffles__create-raffle-form .option-input+label,
.dashboard-raffles__create-raffle-form .checkbox-input+label {
    display: inline-block;
    width: auto;
    color: #4E4E4E;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.dashboard-raffles__create-raffle-form .option-input:focus+label,
.dashboard-raffles__create-raffle-form .checkbox-input:focus+label,
.dashboard-raffles__create-raffle-form .option-input:active+label,
.dashboard-raffles__create-raffle-form .checkbox-input:active+label {
    color: #4E4E4E;
}

.dashboard-raffles__create-raffle-form .option-input:checked+label,
.dashboard-raffles__create-raffle-form .checkbox-input:checked+label {
    color: white;
}

.dashboard-raffles__create-raffle-form .button {
    font: inherit;
    line-height: normal;
    cursor: pointer;
    background: #E8474C;
    color: white;
    font-weight: bold;
    width: auto;
    margin-left: auto;
    font-weight: bold;
    padding-left: 2em;
    padding-right: 2em;
}
.dashboard-raffles__create-raffle-form .green-button {
    background: #47e87a;
}
.dashboard-raffles__create-raffle-form .button:hover,
.dashboard-raffles__create-raffle-form .button:focus,
.dashboard-raffles__create-raffle-form .button:active {
    color: white;
    border-color: white;
}

.dashboard-raffles__create-raffle-form .button:active {
    position: relative;
    top: 1px;
    left: 1px;
}


.dashboard-raffles__create-raffle-form {
    width: 400px;
    max-width: 80vw;
    margin: 0 auto;
    padding: 2rem !important;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.dashboard-raffles__create-raffle-form .field {
    width: 100%;
    margin: 0 0 1.5em 0;
}

@media screen and (min-width: 442px) {
    .dashboard-raffles__create-raffle-form .field.half {
        width: calc(50% - 1px);
    }
}

.dashboard-raffles__create-raffle-form .field.last {
    margin-left: auto;
}

.dashboard-raffles__create-raffle-form .textarea {
    max-width: 100%;
}

.dashboard-raffles__create-raffle-form .select {
    text-indent: 0.01px;
    text-overflow: "" !important;
}

.dashboard-raffles__create-raffle-form .select::-ms-expand {
    display: none;
}

.dashboard-raffles__create-raffle-form .checkboxes,
.dashboard-raffles__create-raffle-form .options {
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow: hidden;
}

.dashboard-raffles__create-raffle-form .checkbox,
.dashboard-raffles__create-raffle-form .option {
    float: left;
    margin: 1px;
}

.customSelect {
    pointer-events: none;
}

.customSelect:after {
    content: "";
    pointer-events: none;
    width: 0.5em;
    height: 0.5em;
    border-style: solid;
    border-color: white;
    border-width: 0 3px 3px 0;
    position: absolute;
    top: 50%;
    margin-top: -0.625em;
    right: 1em;
    transform-origin: 0 0;
    transform: rotate(45deg);
}

.customSelect.customSelectFocus:after {
    border-color: white;
}

.magic-focus {
    position: absolute;
    z-index: 0;
    width: 0;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.15);
    transition: top 0.2s, left 0.2s, width 0.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    will-change: top, left, width;
    transform-origin: 0 0;
}