@import url('https://fonts.googleapis.com/css2?family=Prompt&family=Signika+Negative&display=swap');

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.wallet-adapter-modal {
    z-index: 9999999 !important;
}

.utility__button-purple {
    font-size: 1rem !important;
    background-color: rgb(76 68 164) !important;
    color: white !important;
    line-height: inherit !important;
    height: auto !important;
    padding: 0.8rem 1rem !important;
    transition: all ease-in 0.2s !important;
}

.utility__button-purple:hover {
    background-color: rgb(50 44 113) !important;
}

.utility__button-purple .wallet-adapter-button-start-icon,
.utility__button-purple .wallet-adapter-button-start-icon img {
    height: 1rem !important;
    width: 1rem !important;
}

.font-signika {
    font-family: 'Signika Negative';
}

/* Modal  */
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    animation-name: Modal-Wrapper;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 99999;
}

@keyframes Modal-Wrapper {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

.modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem);
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.modal {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #10141f;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 60%);
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    flex: 1 1;
}

.modal-close-btn {
    padding: 2px 5px;
    display: inline-block;
    cursor: pointer;
    background: #1a1f2e;
    height: 1.2rem;
    border: none;
    border-radius: 50%;
    transition: all ease-in 0.2s;
    line-height: initial;
}

.modal-close-btn:hover {
    background-color: #3c4150;
}

.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    transition: opacity linear 150ms;
    animation-name: Modal-Wrapper;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    z-index: 0;
    overflow-x: hidden;
}


.MuiTooltip-popper {
    z-index: 999999 !important
}

.flex-row {
    display: flex;
    flex-flow: row;
}

.flex-column {
    display: flex;
    flex-flow: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;

}

.align-center {
    align-items: center;
}