@font-face {
    font-family: 'Fjalla One';
    src: url(./../assets/fonts/Fjalla\ One.ttf);
}

@font-face {
    font-family: 'Robot 9000';
    src: url(./../assets/fonts/Robot\ 9000.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Prompt&family=Signika+Negative&display=swap');

* {
    --text-content-size: 2rem
}

html {
    scroll-behavior: smooth;
  }

/* Header  */
.header {
    min-height: 100vh;
    background-color: #A155FF;
    background-image: linear-gradient(to bottom right, #A155FF, #3600AA);
}

.header-text-img {
    width: 100%;
}

.header-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 95vw;
    overflow: hidden;
    top: 10vh;
}

.header-text {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    font-family: 'Robot 9000';
    font-size: var(--text-content-size);
    width: 35vw;
    text-align: center;
    color: #D25827;
}

.header-text-content {
    width: 100%;
    margin-bottom: 20px;
}


.header-image {
    max-width: 100%;
    height: 80vh;
}

@media screen and (max-width: 1060px) {
    .header-text {
        width: 500px;
        max-width: 80vw;
    }

    .header-content {
        width: 100vw;
        justify-content: center;
    }

    .header-image {
        height: 60vh;
        max-width: 100%;
    }

    * {
        --text-content-size: 1.5rem
    }
}

/* Features  */
.features {
    background-color: #1b0750;
    overflow: hidden;
}

.features-flex {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.features-title {
    font-size: 3rem;
    padding-top: 30px;
    color: white;
    text-decoration: underline;
    text-underline-position: under;
    font-family: 'Robot 9000';
    display: flex;
    justify-content: center;
}


.feature-box {
    width: 300px;
    padding: 20px;
    max-width: 80vw;
    box-shadow: 5px 10px #483f3f;
    margin: 30px;
    border-radius: 40px;
    /* background-color: rgba(164, 159, 159, 0.87); */
    background: linear-gradient(to bottom right, rgba(164, 159, 159, 0.87), #454141);
}

.feature-img {
    height: 75px;
    display: block;
    margin: auto;
}
.feature-title {
    text-align: center;
    color: #D25827;
    font-family: 'Signika Negative', sans-serif;
    font-weight: 600;
    font-size: 2rem;   
    flex-flow: row wrap;
}

.feature-description {
    text-align: center;
    font-family: 'Signika Negative', sans-serif;
    font-size:1.5rem;
}


/* Products  */
.products {
    background-color: #010012;
    overflow: hidden;
}


.products-flex {
    margin-top: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.products-title {
    font-size: 3rem;
    padding-top: 30px;
    color: white;
    text-decoration: underline;
    text-underline-position: under;
    font-family: 'Robot 9000';
    display: flex;
    justify-content: center;
}

.product-box {
    width: 300px;
    padding: 20px;
    max-width: 80vw;
    margin: 30px;
    border-radius: 40px;
    box-shadow: 5px 10px crimson;
    background: linear-gradient(to bottom right, #010032, #010020);;
}

.product-img {
    height: 120px;
    display: block;
    margin: auto;
    margin-bottom: 15px;
}
.product-title {
    text-align: center;
    color: cyan;
    font-family: 'Robot 9000', 'Arial Narrow', Arial, sans-serif;
    font-weight: 300;
    font-size: 2.4rem;   
    flex-flow: row wrap;
}

.product-description {
    padding-top: 20px;
    text-align: center;
    color: white;
    /* font-family: 'Fjalla One', 'Arial Narrow', Arial, sans-serif; */
    font-family: 'Signika Negative', sans-serif;

    font-size:1.5rem;
    line-height: 120%;

}