@font-face {
    font-family: 'Robot 9000';
    src: url(./../assets/fonts/Robot\ 9000.ttf);
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Prompt&family=Signika+Negative&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@800&display=swap');

::-webkit-scrollbar {
    width: 0px;
}

/* Navbar  */
.navbar {
    z-index: 1;
    overflow-x: hidden;
    transition: all ease-in 0.5s;
    background: transparent;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: transparent;
    font-size: var(--text-content-size);
}


.nav-no-background {
    background-color: rgba(255, 255, 255, 0.4);
}


.navbar-content {
    display: flex;
    width: 95vw;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.navbar-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.navbar-links-mobile {
    display: flex;
    position: fixed;
    top: 20vh;
    flex-flow: row wrap;
    font-size: 2.5rem;
    justify-content: space-between;
    align-items: center;

}

.navbar-link-btn-mobile {
    display: block;
    margin: auto;
    width: 80vw;
    margin-left: 2vw;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Robot 9000';
    color: #D0E8FF;
    /* text-shadow: 0 0 5px #003761; */
    text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761;

}

.navbar-link-btn {
    margin-left: 2vw;
    padding: 10px;
    font-family: 'Montserrat';
    color: #D0E8FF;
    font-weight: 900;
    /* text-shadow: 0 0 5px #003761; */
    text-shadow: -1px 0 #003761, 0 1px #003761, 1px 0 #003761, 0 -1px #003761;
    font-size: 1.4rem;
}


.navbar-link-active {
    background-color: aqua;
    border-radius: 300px;
}

.navbar-link-btn:hover {
    background-color: aqua;
    cursor: pointer;
    opacity: 80%;
    transition: all 0.3s;
    border-radius: 300px;
}


.navbar-link-btn-mobile:hover {
    background-color: aqua;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 300px;
}



.mobile-navbar-view-1 {
    position: relative;
    display: flex;
    flex-flow: column;
    top: 10%;
    width: 100%;
}

.navbar-connect-button-react-ui {
    padding: 10px !important;
    font-family: 'Robot 9000' !important;
    color: #D0E8FF !important;
    text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761 !important;
}

#mobile-navbar {
    width: 100vw;
    height: 0px;
    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background: rgba(0, 0, 0, 52%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(10px);
}

#hamburger-icon {
    font-family: 'Robot 9000';
    color: #D0E8FF;
    /* text-shadow: 0 0 5px #003761; */
    text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761;
    font-size: 2rem;
    display: none;
}




@media screen and (max-width: 1310px) {
    * {
        --text-content-size: 1.5rem;
    }
}

@media screen and (max-width: 1263px) {
    .navbar-links {
        display: none;
    }

    /* .navbar-connect {
        display: none !important;
    } */

    .navbar-content {
        justify-content: space-between;
    }

    #hamburger-icon {
        display: block;
    }

    #navbar-checkbox-id:checked~#mobile-navbar {
        height: 100vh;
    }

}

/* Fonts  */
.font-signika {
    font-family: 'Signika Negative', sans-serif;
}

.font-robot {
    font-family: 'Robot 9000';
}

.font-baloo {
    font-family: 'Baloo 2', 'Montserrat', 'sans-serif';
}

/* Modal  */
.modal-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    background: rgba(0, 0, 0, 52%);
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(10px);
    overflow: scroll;
    scrollbar-width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    width: 700px;
    padding: 50px;
    max-width: 70vw;
    background-color: #57545463;
}

/* Wallet Connect */
.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 52%) !important;
    backdrop-filter: blur(10px) !important;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 31%) !important;
}

.wallet-connect-btn-eth {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: transparent;
    color: #fff;
    cursor: pointer;
    font-weight: 400;
    border-radius: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 48px;
}

.wallet-connect-btn-eth:hover {
    background-color: #1a1f2e;
}

.wallet-connect-choose-chain-modal {
    width: 700px;
    padding: 50px;
    max-width: 70vw;
    animation-name: fade-in-moving-up;
    animation-duration: 0.5s;
    background-color: #57545463;
}

.wallet-adapter-modal-wrapper {
    overflow: hidden;
    position: absolute !important;
    width: 700px !important;
    max-width: 80vw !important;
    background-color: #57545463 !important;
}

/* Buttons  */
.button-orange-border {
    border: 5px solid #D25827 !important;
    padding: 10px !important;
    border-radius: 420px !important;
    font-family: 'Robot 9000' !important;
    background-color: transparent !important;
    color: #D0E8FF !important;
    display: flex !important;
    align-items: center !important;
    flex-flow: row wrap !important;
    justify-content: center !important;
    flex-flow: row wrap !important;
    line-height: normal !important;
    height: auto !important;
    font-weight: 400 !important;
    text-shadow: -2px -2px 0 #003761,
        2px -2px 0 #003761,
        -2px 2px 0 #003761,
        2px 2px 0 #003761;
}

.button-cyan-border {
    border: 5px solid #13fef2 !important;
}

.button-orange-border:hover {
    background-color: #f4875c !important;
    cursor: pointer !important;
    transition: all 0.3s !important;
}

.button-cyan-border:hover {
    background-color: #13e0d6 !important;
}

/* Animation  */

@keyframes fade-in-moving-up {
    from {
        opacity: 0%;
        margin-top: 40px;
    }

    to {
        margin-top: 0px;
        opacity: 100%;
    }
}

/* Alert  */
.alert {
    position: fixed;
    bottom: 2vh;
    display: inline-block;
    right: 10px;
    z-index: 100000;
}

.alert-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-flow: column;
}

.alert-info {
    padding: 10px;
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 10px;
    animation-name: alert-popup;
    animation-duration: 3s;
}

@keyframes alert-popup {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

/* Transitions  */

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

/* Btns  */
.utility__btn-purple {
    background: rgb(79 96 188);
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 112px;
    text-align: center;
    color: white;
    border: 1px solid transparent;
    padding: 0.3rem 1.3rem;
    box-shadow: 0.2rem 0.2rem #3b38ad;
    font-weight: 100;
    margin-top: 0.6rem;
}

.utility__btn-purple:hover {
    background: #3b38ad;
    box-shadow: 0px 0px;
    transition: all ease-in 0.3s;
    border: 1px solid white;
}

.utility-btns__purple-btn {
    background-color: rgb(44, 44, 79);
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    margin: auto;
    display: inline-block;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 100px;
    border: 1px solid white;
}

.utility-btns__purple-btn:hover {
    cursor: pointer;
    transition: all ease-in 0.2s;
    background-color: rgb(36, 36, 76);
}
.utility-btns__purple-btn:disabled {
    background-color: #404144;
}